<template>
  <div v-if="_g_IsUserLogged">
    <div v-if="_g_ShowStockChartMenu" class="mdc-menu mdc-menu-surface stockchart-menu">
      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
        <li @click="_showFilters" id="0" :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'mdc-list-item' : 'not-allowed-functionality'" :title="!(_g_User.roles[21] || _g_User.roles[20]) ? 'Filtros' : 'Su plan actual no tiene acceso a filtros'">
          <span class="mdc-list-item__text">
              <span :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'material-icons-outlined md32' : 'material-icons-outlined not-allowed-filters-stocks'" :style="allow_functionality">filter_alt</span>
              <span class="material-label-ekers typo-caption">Filtros</span>
          </span>
        </li>
        <div style="display:flex;">
          <li @click="_openFavorites()" id="1" :class="!_g_User.is_only_authenticated_user ? 'mdc-list-item' : 'not-allowed-functionality'" role="menuitem" title="Favoritos">
            <span class="mdc-list-item__text">
              <span :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'material-icons-outlined md32' : 'material-icons-outlined not-allowed-favorites-stocks'">grade</span>
              <span class="material-label-ekers typo-caption">favoritos</span>
            </span>
          </li>
        </div>
        <li :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'mdc-list-item' : 'not-allowed-functionality'" @click="_setPlatinumActive()" id="2" role="menuitem" routername="Stockchart" :title="!(_g_User.roles[21] || _g_User.roles[20]) ? 'Valores Platino' : 'Su plan actual no tiene acceso a valores platino'">
          <span class="mdc-list-item__text">
              <span class="material-label-ekers-platinum-letter">P</span>
              <span class="material-label-ekers typo-caption">Platinum</span>
          </span>
        </li>
        <li :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'mdc-list-item' : 'not-allowed-functionality'" id="3" role="menuitem" routername="Stockchart" @click="_openSearchesWindow()" :title="!(_g_User.roles[21] || _g_User.roles[20]) ? 'Búsquedas Guardadas' : 'Su plan actual no tiene acceso a búsquedas guardadas'">
          <span class="mdc-list-item__text">
              <span :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'material-icons-outlined md32' : 'material-icons-outlined not-allowed-searches-stocks'" :style="allow_functionality">folder</span>
              <span class="material-label-ekers typo-caption">Búsquedas</span>
          </span>
        </li>
        <li :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'mdc-list-item' : 'not-allowed-functionality'" id="4" role="menuitem" routername="Stockchart" @click="_openTickersFinder()" :title="!(_g_User.roles[21] || _g_User.roles[20]) ? 'Búsquedas por descripción' : 'Su plan actual no tiene acceso a búsquedas por descripción'">
          <span class="mdc-list-item__text">
              <span :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'material-icons-outlined md32' : 'material-icons-outlined not-allowed-finder-stocks'" :style="allow_functionality">search</span>
              <span class="material-label-ekers typo-caption">Rastrear</span>
          </span>
        </li>
        <li :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'mdc-list-item' : 'not-allowed-functionality'" id="5" role="menuitem" routername="Stockchart" @click="_openPortfolioContainer" :title="!(_g_User.roles[21] || _g_User.roles[20]) ? 'Valores En Cartera' : 'Su plan actual no tiene acceso a valores en cartera'">
          <span class="mdc-list-item__text">
              <span :class="!(_g_User.roles[21] || _g_User.roles[20] || _g_User.is_only_authenticated_user) ? 'material-icons-outlined md32' : 'material-icons-outlined not-allowed-finder-stocks'" :style="allow_functionality">account_balance_wallet</span>
              <span class="material-label-ekers typo-caption">En cartera</span>
          </span>
        </li>
      </ul>
      <!-- Container for Searches Modal -->
      <div v-if="show_searches" class="modal-container">
        <div class="modal-backdrop" @click="_closeSearchWindow"></div>
        <!-- Actual Searches component, positioned by modal-container -->
        <Searches
          :show_searches="show_searches"
          @show_searches="show_searches=$event"
          @_closeSearchWindow="_closeSearchWindow"
        />
      </div>
  
      <div v-if="_g_TickersFinder" class="modal-container">
        <div class="modal-backdrop" @click="_closeWindow()"></div>
        <div>
          <TickersFinder>
          </TickersFinder>
        </div>
      </div>

      <div v-if="show_valores_cartera" class="modal-container">
        <div class="modal-backdrop" @click="_closeWindowValores()"></div>
        <ValoresCartera @close-component="_closePortfolioContainerFromComponent()"/>
      </div>

      <!-- Container for Favoritos Modal -->
      <div v-if="show_link_favorites" class="modal-container">
        <div class="modal-backdrop" @click="_closeFavorites"></div>
        <!-- Add workspace_edited event handler to update colors in stocks list -->
        <Favoritos 
          @_closeFavoriteWorkspacesWindow="_closeFavorites"
          @workspace_edited="_workspaceEdited"
        />
      </div>

    </div>
  </div>

  <div v-else>
    <div v-if="_g_ShowStockChartMenu" class="mdc-menu mdc-menu-surface stockchart-menu">
      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
        <li @click="_showFilters" id="0" class="not-allowed-functionality">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined not-allowed-filters-stocks">filter_alt</span>
              <span class="material-label-ekers typo-caption">Filtros</span>
          </span>
        </li>
        <div style="display:flex;">
          <div>
            <li @click="show_link_favorites=!show_link_favorites" id="1" class="mdc-list-item" role="menuitem"><!-- data-routername="Favoritos" -->
              <span class="mdc-list-item__text">
                <span class="material-icons-outlined md32">grade</span>
                <span class="material-label-ekers typo-caption">favoritos</span>
              </span>
            </li>
          </div>
          <div v-if="show_link_favorites" id="favorites-container" class="content-link-favorites-link" role="menuitem">
            <div class="window-favorites" @click="_closeFavorites()"></div>
            <div class="favorite-stars-container-stockchart-menu" style="display: flex;">
              <a v-for="(workspace, index) in workspaces" @click="_findFavoritesStocksByName(workspace)" :key="index">
                <span :title="workspace.name" class="material-icons-outlined md32" :style="{ color:workspace.color}">grade</span>
              </a>
            </div>
            <div class="separator-container" style="margin-top: 8px;">
              <hr>
            </div>
            <div class="text-favorite-container"  style="display: flex;">
              <div style="margin-top:10px;">
                <span class="favorite-text">FAVORITOS</span>
              </div>
              <span @click="_closeFavorites()" class="close" style="color: #00add8; margin-right: 10px">X</span>
            </div>
          </div>
        </div>
        <li @click="_setPlatinumActive()" id="2" class="mdc-list-item" role="menuitem" routername="Stockchart">
          <span class="mdc-list-item__text">
              <span class="material-label-ekers-platinum-letter">P</span>
              <span class="material-label-ekers typo-caption">Platinum</span>
          </span>
        </li>
        <li  class="mdc-list-item" id="3" role="menuitem" routername="Stockchart" @click="_openSearchesWindow()">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">folder</span>
              <span class="material-label-ekers typo-caption">Búsquedas</span>
          </span>
        </li>
        <li class="not-allowed-functionality" id="4" role="menuitem" routername="Stockchart" title="Búsquedas por descripción">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined not-allowed-finder-stocks">search</span>
              <span class="material-label-ekers typo-caption">Rastrear</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>



<script>
  import APICaller from "@/components/codeComponents/APICaller.js";
  import { mapMutations, mapGetters } from "vuex";
  import { MDCMenu } from '@material/menu';
  import Searches from '@/components/stockchart/components/Searches.vue';
  import Favoritos from '@/views/Stockchart/Favoritos.vue';
  import TickersFinder from '@/components/stockchart/components/TickerFinder.vue';
  import ValoresCartera from '@/components/stockchart/components/ValoresCartera.vue';
import { throwStatement } from "@babel/types";

  export default {
    mixins:[APICaller],
    name: 'StockchartMenu',
    components: {
      Searches,
      Favoritos,
      TickersFinder,
      ValoresCartera
    },
    data() {
      return {
        workspaces: [],
        last_element_active: '',
        show_link_favorites: false,
        sidebar_right_menu_width: 52,
        parent_component: 'sidebar-content',
        tradingview_container: 'dragable-container',
        show_searches: false,
        numberStocks: '',
        workspace: '',
        show_tickers_finder: false,
        show_valores_cartera: false,
        allow_functionality: '',
      };
    },
    watch:{  
      changeSelection: {      
        handler: function() {
          if (this.lastIndex !== 2) {
            var element2 = document.getElementById(this.lastIndex.toString());
            element2.classList.remove("eurekers-parent-menu-active");
          }
          this.last_element_active = this.lastIndex;                                   
        },
        deep: true
      },  
      workspace_created: {
        handler() {
          this.workspace_created && this._listWorkspaces();
          this.setWorkspaceCreated(false)
        },
        deep: true,
      }, 
    },
    mounted() {    
      const menu = new MDCMenu(document.querySelector('.mdc-menu'));
      const adapter = menu.getDefaultFoundation().adapter; 
      
      if(window.location.href.indexOf("platinum") > -1){
        adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
      }
      
      if(this._g_IsUserLogged){
        menu.listen('MDCMenu:selected', (event) => {
          var item = document.getElementById(event.detail.index.toString());
          
          if(event.detail.index == 2){
            var platinum = document.getElementById('2');
            var hasPlatinum = platinum.classList.contains('eurekers-parent-menu-active');
            if(hasPlatinum){
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');
            }else{
              adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
            }
          }
          
          if(event.detail.index == 0 || event.detail.index == 1 || 
             event.detail.index == 3 || event.detail.index == 4 || 
             event.detail.index == 5) {
            adapter.removeClassFromElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
          }
                  
          this.last_element_active = event.detail.index;
          });
      }        
      else{
        menu.listen('MDCMenu:selected', (event) => {
          var item = document.getElementById(event.detail.index.toString());
          
          if(event.detail.index == 2){
            var platinum = document.getElementById('2');
            var hasPlatinum = platinum.classList.contains('eurekers-parent-menu-active');
            if(hasPlatinum){
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');
            }else{
              adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
            }
          }
          
          if(event.detail.index == 0 || event.detail.index == 1 || 
             event.detail.index == 3 || event.detail.index == 4) {
            adapter.removeClassFromElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
          }
                  
          this.last_element_active = event.detail.index;
          }); 
      }
      this._listWorkspaces();

      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var mode = urlParams.get('mode');
      if( mode == 'list'){
        this._toggleBetweenListAndChartInStockchart();
        adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
        adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
        this.last_element_active = 2;
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if(params.filters_open == "true"){
        this.setShowStockFinderFilters();
      }
      this._set_component_style();
    },
    computed: {
        ...mapGetters(['_g_IsUserLogged']),
        ...mapGetters(['_g_ToggleBetweenTableAndChart']),
        ...mapGetters(['_g_ShowStockChartMenu']),
        ...mapGetters(['_g_ShowStockFinderFilter']),
        ...mapGetters({workspace_created: '_g_workspace_created'}),
        ...mapGetters({changeSelection:'_g_MenuSelectionOut'}),
        ...mapGetters({lastIndex:'_g_IndexMenu'}),
        ...mapGetters({stock_type:'_g_GetTypeStocks'}),
        ...mapGetters(['_g_TickersFinder']),
        ...mapGetters(["_g_User"]),
             
    },
    methods: {
        ...mapMutations(['setMenuSelectionActive', 'setPlatinumActive', 'setShowStockFinderFilter', 'setFiltersPlatinum', 'setTickersFinder', 'setWorkspaceCreated', 'setStockchart']),
        ...mapMutations(['setToggleBetweenListAndChartInStockchart']),
        ...mapMutations(['setShowStockFinderFilters']),
        ...mapMutations(['setSelectedFavoriteWorkspace']),
        ...mapMutations(['setShowStockChartMenu']),
        ...mapMutations(['isMobileDevice']),
        ...mapMutations(['setStockValue']),
        ...mapMutations(['setMenuSelectionOut']),

        _toggleBetweenListAndChartInStockchart() {
            this.setToggleBetweenListAndChartInStockchart();
        },

        _setStockChart(){
          this.setStockchart();
        },

        _showFilters() {
          if(this._g_User.roles[21] || this._g_User.roles[20] || this._g_User.is_only_authenticated_user){
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          } else {
            if(this.$route.params.param1 != undefined){
              window.sessionStorage.removeItem('ticker_search_by_user');
              this.$router.push("/app/graficador-dinamico?filters_open=true")
            }else{
              this.setShowStockFinderFilters();                  
            }
          }
        },

        _closeFilters(){
          this.setShowStockFinderFilters();
        },

        _setPlatinumActive(){
          if(this._g_User.roles[21] || this._g_User.roles[20] || this._g_User.is_only_authenticated_user){
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          } else {
            if(this.$route.params.param1 == "platinum"){
              this.$router.push('/app/graficador-dinamico')
            }else{
              this.$router.push('/app/graficador-dinamico/platinum');
            } 
          }
        },

        _getStocksInWorkspace() {
          let success = response =>{
            this.numberStocks = response.data.length;
          }

          let url = '/api/v1/stock-finder/stocks/favoritos/' + this.workspace.name

          const successHandler = new APICaller.SuccessHandler(success);
          const failureHandler = new APICaller.FailureHandler('170', 'Error recuperando valores.');

          this._getAPICall(url, successHandler, failureHandler);
        },

        _findFavoritesStocksByName(workspace) {
          this.workspace = workspace;
          this.numberStocks = this._getStocksInWorkspace();
          setTimeout(() => {
            if(this.numberStocks != 0){
              this.show_link_favorites = !this.show_link_favorites;          
              this.setSelectedFavoriteWorkspace(workspace.name);
              if (this.$router.history.current.name === 'Favoritos') {
                this.$vueOnToast.pop('warning', "Ya estás en favoritos");
              }
              else {
                this.$router.push('/app/graficador-dinamico/favorites/' + workspace.name);
              }
            }
            else{
              if(this._g_IsUserLogged){
                this.$vueOnToast.pop('error', "No existen tickers en este workspace", "Por favor añada tickers al workspace" + ' - Error code 003.1');
              }
            } 
          }, 200);
          
                   
        },
        _openSearchesWindow: function(){
          if(this._g_User.roles[21] || this._g_User.roles[20] || this._g_User.is_only_authenticated_user){
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          } else{
            this.show_searches = !this.show_searches;
          }
        },
        _closeSearchWindow: function(){
          this.show_searches = false;
          this.setMenuSelectionOut(5);
        },

        _listWorkspaces: function() {
          if(!this._g_User.is_only_authenticated_user){
            let success = response =>{
              if (response.data == [] || response.data.folders == undefined) {
                this.workspaces = [];
              }
              else {
                this.workspaces = [];
                for (let index = 0; index < response.data.folders.length; index++) {
                  this.workspaces.push({
                    name: response.data.folders[index][0],
                    color: response.data.folders[index][1],
                    isFavorite: false,
                  });
                }
              }
            }
  
            let failure = ()=>{
              if(this._g_IsUserLogged){
                this.$vueOnToast.pop('error', "No existen tickers en este workspace", "Por favor añada tickers al workspace" + ' - Error code 003.1');
              }
            }
  
            let url = '/api/v1/stock-finder/favorite-folder/list'
  
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('171', 'No existen tickers en este espacio de trabajo.');
  
            this._getAPICall(url, successHandler, failureHandler);
          }
        },
        _openFavorites: function(){
          if(!this._g_User.is_only_authenticated_user){
            this.show_link_favorites = !this.show_link_favorites;
          } else{
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          }
        },
        _closeFavorites: function(){
          this.show_link_favorites = false;
          this.setMenuSelectionOut(1);
        },

        _toastIniciaSesion : function(){
          this.$vueOnToast.pop('error', "Si quiere acceder a esta herramienta inicie sesión.");
        },

        _closeWindow(){
          this.setTickersFinder();
        },
        _closeWindowValores: function(){
          this.show_valores_cartera = false;
        },
        _openPortfolioContainer: function(){
          if(this._g_User.roles[21] || this._g_User.roles[20] || this._g_User.is_only_authenticated_user){
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          } else{
            this.show_valores_cartera = !this.show_valores_cartera
          }
        },
        _closePortfolioContainerFromComponent: function(){
          this.show_valores_cartera = false
        },
        _set_component_style(){
          if(this._g_User.roles[21] || this._g_User.roles[20] || this._g_User.is_only_authenticated_user){
            this.allow_functionality = 'cursor: not-allowed;';
          }
        },
        _workspaceEdited: function() {
          // Clear existing workspaces first
          this.workspaces = [];
          
          // Refresh the workspaces list
          this._listWorkspaces();
          
          // Propagate the event to parent components to refresh stocks
          // Delay propagation slightly to ensure everything has updated
          setTimeout(() => {
            this.$emit('workspace_edited');
          }, 100);
        },
        _openTickersFinder: function(){
          if(this._g_User.roles[21] || this._g_User.roles[20] || this._g_User.is_only_authenticated_user){
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          } else{
            this.setTickersFinder();
          }
        },
    },
}
</script>



<style lang="scss" scoped>
  @use "@material/list/mdc-list";
  @use "@material/menu/mdc-menu";

  @import "@/components/stockchart/styles/stockchart_menu.scss";

  li.eurekers-parent-menu-active {
      background: var(--color-custom-active-parent-menu);
      span.material-icons-outlined,
      span.material-label-ekers typo-caption,
      span.material-label-ekers typo-caption-platinum-letter {
          color: #00AEDD;
      }
  }

  a { text-decoration: none }

  .content-link-favorites-link a {
      margin: 4px;
  }

  .content-link-favorites-link {
      position: absolute;
      width:230px;
      background-color: #f3f4f7;
      border-left-style: none;
      box-shadow: 0px 2px 3px #A3A3A3;
      padding: 10px;
      border: 1px solid #b9b9b9;
      border-radius:12px;
      margin-left: 80px;
  }
  hr{  
      border: none;
      border-left: 1px solid hsla(200, 10%, 50%,100);
      height: 2vh;
      width: 1px; 
      margin:none;   
      margin: 0em;
      margin-top:5px;
      border-width: 1.5px;   
  }
  .favorite-text{
    font-size: 14px;
    margin-right: 20px;
    font-weight: 600;
  }
  .favorite-stars-container-stockchart-menu{
    float: left;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    .icons-container{
      display:none;
    }
  }

  .favorite-stars-container-stockchart-menu:hover{
    float: left;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    .icons-container{
      display:block;
    }
  }

  .anonymous :hover{
    cursor: not-allowed;
  }

  .favorites-close-icon-container{
    float: right;
  }

  .favorite-title-container{
    margin-left: 70px;
    float: left;
  }

  .star-style{
    cursor:pointer;
  }
  .workspace-name-style:hover{
    color: #0E5F85; 
    cursor:pointer;
  }
  .workspace-name-style{
    margin-left: 10px;
  }

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
  }

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 15, 26, 0.7); /* --color-background with alpha */
    z-index: 1001; /* Backdrop behind modal content */
  }

  /* Ensure the modal content itself is above the backdrop */
  .modal-container > :not(.modal-backdrop) {
    z-index: 1002; 
  }

  .not-allowed-functionality{
    cursor: not-allowed;
    justify-content: center;
    width: 80px;
    padding: 14px 0;
    margin: 0;
    height: 48px;
    display: flex;
    position: relative;
    align-items: center;
  }

  .not-allowed-searches-stocks,
  .not-allowed-favorites-stocks,
  .not-allowed-finder-stocks,
  .not-allowed-filters-stocks{
    cursor: not-allowed;
  }

</style>
