<template>
<div v-if="is_streaming == false" style="height: 100%">
  <LoadingForum v-if="isLoading" ref="loader" @loaded="handleLoaderFinished" :message="loadingMessage" class="full-screen-loader" />
  <div v-if="!isMobile" class="stockchart-default" :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
    <div id="ai">
      <TopBarAd v-if="_g_UserFeatures && !_g_UserFeatures.has_active_role" />
      <Ai v-else />
    </div> 
      
    <div id="stockchart-menu">
      <StockchartMenu @workspace_edited="_refreshWorkspaceColors" />
    </div>   

    <div>
        <StockchartMenuSecundario ref="stockchartMenuSecundario" />
    </div>

    <Stockchart @stocks-loaded="finishLoading" />

    <div id="sidebar-content">
      <div v-if="_g_IsUserLogged" class="components">
        <Comments />
        <Fundamental />
        <Info v-if="!(_g_User.roles[21] || _g_User.roles[20])"/>
        <InfoBasico v-else />
        <Alarmas />
        <Error />
        <Simulator />
        <Description />
        <Notas />
        <Registro />
        <Fullscreen />
        <Order @_setBrokerConnectionStatus="_setBrokerConnectionStatus" />
      </div>
    </div>
  </div>
  <div v-else-if="isMobile" class="flex-container mobile-content" :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">          
    <StockchartMenuMobile ref="topmenu" @next-ticker="_nextTicker()" @prev-ticker="_prevTicker()" @change-chart-list="_changeChartList()"/>
    
    <div v-if="_g_toggle_chart_stocks=='chart'">
      <div v-show="_g_chart_view == true">
        <div v-if="_g_ShowStockFinderFilter == true" style="z-index:0;">
          <FinderMobile></FinderMobile>
        </div>
        <div v-if="_g_open_favorites == true">
          <FavoritosMobile @_closeComponentFavoritesMobile="_closeComponentFavoritesMobile()"></FavoritosMobile>
        </div>
        <div v-if="_g_open_searches == true">
          <Searches 
          :show_searches="show_searches"
          @show_searches="show_searches=$event"
          @_closeSearchWindow="_closeSearchWindow" />
        </div>
        <div v-if="_g_open_info==true">
          <InfoMobile v-if="!(_g_User.roles[21] || _g_User.roles[20])" @_closeComponentInfoMobile="_closeComponentInfoMobile()"></InfoMobile>
          <InfoMobileBasico v-else @_closeComponentInfoMobile="_closeComponentInfoMobile()"></InfoMobileBasico>
        </div>
        <div v-if="_g_open_fundamental==true">
          <FundamentalMobile @_closeComponentFundamentalMobile="_closeComponentFundamentalMobile()"></FundamentalMobile>
        </div>
        <div v-if="_g_open_description==true">
          <DescriptionMobile @_closeComponentDescriptionMobile="_closeComponentDescriptionMobile()"></DescriptionMobile>
        </div>
        <div v-if="_g_open_comments==true">
          <CommentsMobile @_closeComponentCommentsMobile="_closeComponentCommentsMobile()"></CommentsMobile>
        </div>
        <div v-if="_g_open_error==true">
          <ErrorMobile @_closeComponentErrorMobile="_closeComponentErrorMobile()"></ErrorMobile>
        </div>
        <div v-if="_g_open_simulator==true">
          <SimulatorMobile @_closeComponentSimulatorMobile="_closeComponentSimulatorMobile()"></SimulatorMobile>
        </div>
        <div v-if="_g_open_order==true">
          <OrderMobile @_closeComponentOrderMobile="_closeComponentOrderMobile()"></OrderMobile>
        </div>
        <div v-if="_g_open_notas==true">
          <RegistroMobile @_closeComponentNotasMobile="_closeComponentNotasMobile()"></RegistroMobile>
        </div>
        <div v-show="_g_TickersFinder==true">
          <TickerFinderMobile @_closeComponentDescriptionSearchMobile="_closeComponentDescriptionSearchMobile()"></TickerFinderMobile>
        </div>
        <StockchartMenuMobile2 ref="stockchart_mobile_bottom" />
      </div>
      <div v-show="_g_chart_view == false" style="height: 300px; margin-bottom: 5px;">
        <div v-if="this.portfolio_selected.length != 0 && !_g_User.is_only_authenticated_user" class="cartera-mobile">
          <SelectOutlineWallet
            id="cartera-element"
            style="border-radius:20px;"
            :options="this.user_portfolios"
            :default_value="this.portfolio_selected[0].name"
            :component_class="select_items[0].component_class"
            @_emit_method_order_select="_changePortfolioSelected"
          />
        </div>
        <div @click="_toastError()" v-else-if="_g_User.is_only_authenticated_user" class="cartera-mobile">
          <SelectOutlineWallet
            id="cartera-element"
            style="border-radius:20px;"
            :options="this.user_portfolios"
            :default_value="this.portfolio_selected[0].name"
            :component_class="select_items[0].component_class"
            @_emit_method_order_select="_changePortfolioSelected"
          />
        </div>
        <div v-if="is_a_search" class="searches-tittle">
          <span class="text-searches">{{search_text}}</span><span class="material-icons-outlined close-searches" @click="_closeSearch()">close</span>
        </div>
        <div v-if="!_g_User.is_only_authenticated_user">
          <InformationMobile ref="information"/>
        </div>        
        <div style="height:100%">
          <Stockchart />          
        </div>
        <div v-if="_g_IsUserLogged">
          <div style="display:flex; height: 44px;">
            <PlatinumMobile/>
            <ActionsMobile/>
          </div>
        </div>
        <div id="ai">
          <AiMobile />
        </div>
        <StockchartMenuMobile2 ref="stockchart_mobile_bottom" />
      </div>            
    </div>
    <div v-else>
      <div v-show="_g_stocks_view == true">
        <div v-if="_g_open_info==true">
          <InfoMobile v-if="!(_g_User.roles[21] || _g_User.roles[20])" @_closeComponentInfoMobile="_closeComponentInfoMobile()"></InfoMobile>
          <InfoMobileBasico v-else @_closeComponentInfoMobile="_closeComponentInfoMobile()"></InfoMobileBasico>
        </div>
        <div v-if="_g_open_fundamental==true">
          <FundamentalMobile @_closeComponentFundamentalMobile="_closeComponentFundamentalMobile()"></FundamentalMobile>
        </div>
        <div v-if="_g_ShowStockFinderFilter == true">
          <FinderMobile></FinderMobile>
        </div>
        <div v-if="_g_open_favorites == true">
          <FavoritosMobile @_closeComponentFavoritesMobile="_closeComponentFavoritesMobile()"></FavoritosMobile>
        </div>
        <div v-if="_g_open_searches == true">
          <Searches 
          :show_searches="show_searches"
          @show_searches="show_searches=$event"
          @_closeSearchWindow="_closeSearchWindow" />
        </div>

        <div v-if="_g_open_description==true">
          <DescriptionMobile @_closeComponentDescriptionMobile="_closeComponentDescriptionMobile()"></DescriptionMobile>
        </div>
        <div v-if="_g_open_comments==true">
          <CommentsMobile @_closeComponentCommentsMobile="_closeComponentCommentsMobile()"></CommentsMobile>
        </div>
        <div v-if="_g_open_error==true">
          <ErrorMobile @_closeComponentErrorMobile="_closeComponentErrorMobile()"></ErrorMobile>
        </div>
        <div v-if="_g_open_simulator==true">
          <SimulatorMobile @_closeComponentSimulatorMobile="_closeComponentSimulatorMobile()"></SimulatorMobile>
        </div>
        <div v-if="_g_open_order==true">
          <OrderMobile @_closeComponentOrderMobile="_closeComponentOrderMobile()"></OrderMobile>
        </div>
        <div v-if="_g_open_notas==true">
          <RegistroMobile @_closeComponentNotasMobile="_closeComponentNotasMobile()"></RegistroMobile>
        </div>
        <div v-show="_g_TickersFinder==true">
          <TickerFinderMobile></TickerFinderMobile>
        </div>
        <StockchartMenuMobile2 ref="stockchart_mobile_bottom" />
      </div>
      <div v-show="_g_stocks_view == false">
        <StockMobile ref="stock_mobile" style="margin-top: 20px;"/>
        <StockchartMenuMobile2 ref="stockchart_mobile_bottom" />
      </div>
    </div>
  </div>
</div>
<div v-else-if="is_streaming == true" class="streaming-container">
  <div class="pop-up-streaming">
    <div>
      <h3 style="margin-bottom: 20px;">La emisión en directo de hoy es a las: {{streaming_date}} (Hora peninsular española)</h3>
      <p>Para acceder al streaming haz click <a :href="streaming_url" style="color: blue" target="_blank">AQUÍ</a></p>
    </div>
  </div>
</div>
<div v-else-if="is_streaming == null">
  
</div>

</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import StockchartMenu from '@/components/stockchart/menu/StockchartMenu.vue'
import StockchartMenuSecundario from '@/components/stockchart/menu/StockchartMenuSecundario.vue'
import SelectOutlineWallet from "@/components/helpers/SelectOutline_wallet_mobile.vue";
import SelectWallet from "@/components/stockchart/components/configs/select_ai.json";
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';

import StockchartMenuMobile from '@/components/stockchart/menu/Stockchart_menu_mobile_top.vue'
import StockchartMenuMobile2 from '@/components/stockchart/menu/Stockchart_menu_mobile_bottom.vue'
import InformationMobile from '@/components/stockchart/components/Information_mobile.vue'

import Fundamental from '@/components/stockchart/components/Fundamental.vue'
import Info from '@/components/stockchart/components/Info.vue'
import InfoBasico from '@/components/stockchart/components/InfoBasico.vue'
import Error from '@/components/stockchart/components/Error.vue'
import Alarmas from '@/components/stockchart/components/Alarmas.vue'
import Comments from '@/components/stockchart/components/Comments.vue'
import Simulator from '@/components/stockchart/components/Simulator.vue'
import Description from '@/components/stockchart/components/Description.vue'
import Notas from '@/components/stockchart/components/Notas.vue'
import Registro from '@/components/stockchart/components/Registro.vue'
import Ai from '@/components/stockchart/components/Ai.vue'
import AiMobile from '@/components/stockchart/components/Ai_mobile.vue'
import Fullscreen from '@/components/stockchart/components/Fullscreen.vue'
import Order from '@/components/stockchart/components/Order.vue'
import Stockchart from '@/views/Stockchart/Stockchart.vue'
import FavoritosMobile from '@/views/Stockchart/FavoritosMobile.vue'
import StockMobile from '@/components/stockchart/components/Stocks_mobile.vue';

import InfoMobile from '@/components/stockchart/components/Info_mobile.vue';
import InfoMobileBasico from '@/components/stockchart/components/Info_mobile_basico.vue';
import FundamentalMobile from '@/components/stockchart/components/Fundamental_mobile.vue';
import DescriptionMobile from "@/components/stockchart/components/Description_mobile.vue";
import CommentsMobile from "@/components/stockchart/components/Comments_mobile.vue";
import RegistroMobile from "@/components/stockchart/components/Registro_mobile.vue";
import ErrorMobile from "@/components/stockchart/components/Error_mobile.vue";
import TickerFinderMobile from "@/components/stockchart/components/TickerFinderMobile.vue";
import OrderMobile from "@/components/stockchart/components/Order_mobile.vue";
import SimulatorMobile from "@/components/stockchart/components/Simulator_mobile.vue";
import PlatinumMobile from "@/components/stockchart/components/platinum_mobile.vue";
import ActionsMobile from "@/components/stockchart/components/actions_mobile.vue";

import FinderMobile from '@/components/stockchart/components/Finder/Finder-Base_mobile.vue';
import Searches from '@/components/stockchart/components/Searches_mobile.vue';
import GetStocks from "@/components/codeComponents/GetStocks.js";
import TopBarAd from '@/components/stockchart/components/TopBarAd.vue';

import { mapMutations, mapGetters } from "vuex";

import { isMobile } from 'mobile-device-detect';
import { isTablet } from 'mobile-device-detect';

export default {
  mixins: [APICaller,GetStocks],
  name: 'Base',
  components: {
    Info,
    InfoBasico,
    Error,
    Simulator,
    Comments,
    Description,
    Fundamental,
    StockchartMenu,
    StockchartMenuSecundario,
    Notas,
    Ai,
    Fullscreen,
    Order,
    Stockchart,
    StockchartMenuMobile,
    StockchartMenuMobile2,
    InformationMobile,
    StockMobile,
    InfoMobile,
    InfoMobileBasico,
    FundamentalMobile,
    DescriptionMobile,
    CommentsMobile,
    RegistroMobile,
    ErrorMobile,
    TickerFinderMobile,
    OrderMobile,
    SimulatorMobile,
    Searches,
    PlatinumMobile,
    ActionsMobile,
    SelectOutlineWallet,
    Registro,
    Alarmas,
    AiMobile,
    FinderMobile,
    FavoritosMobile,
    LoadingForum,
    TopBarAd
  },
  data() {
    return {
      isLoading: true,
      loadingMessage: "Cargando graficador...",
      isMobile: window.innerWidth < 901,
      nextTicker: false,
      prevTicker: false,
      isChart: true,
      show_searches: true,
      select_items: SelectWallet,
      portfolio_selected:[],
      user_portfolios: [],
      is_a_search: false,
      search_text: "",
      portfolio_id:'',
      first_show: true,
      is_streaming: null,
      streaming_date: "",
      streaming_url: "",
      myWidth: 0,
      loadingTimeout: null
    };
  },
  created(){
    this._getStreamingInfo();
    document.getElementsByTagName("html")[0].classList.add("noscroll");
  },
  mounted() {
    this.myWidth = window.width;
    this._searchTitle()
    this._getPortfolios();
    var waiting_for_portfolios = setInterval(() => {
      if(this.user_portfolios.length != 0){
        this._defaultPortfolio();
        clearInterval(waiting_for_portfolios);
      }
    }, 200);
    var iframeInterval = setInterval(() => {
      if(document.getElementById("basic-values") != undefined && document.getElementById("basic-values") != null){
        this._setIframe();
        clearInterval(iframeInterval);
      }
    }, 300);
    
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    
    this.loadingTimeout = setTimeout(() => {
      this.finishLoading();
    }, 5000);
  },
  methods: {
    finishLoading() {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
        this.loadingTimeout = null;
      }
      
      if (this.$refs.loader) {
        this.$refs.loader.finishLoading();
      }
    },

    handleLoaderFinished() {
      this.isLoading = false;
    },

    ...mapMutations(['setToggleChartStocks']),
    ...mapMutations(['setOpenFavorites']),
    ...mapMutations(['setChartView']),
    ...mapMutations(['setStocksView']),
    ...mapMutations(['setRechargeState']),
    ...mapMutations(['setPortfolioIdSelected']),
    

    onResize() {
      this.isMobile = window.innerWidth < 901
      if(window.innerWidth < 901 && this.myWidth != window.width){
          window.location.reload()
        }
    },
    
    _closeSearch: function(){
      window.sessionStorage.removeItem('ticker_search_by_user');
      this.$router.push('/app/graficador-dinamico');
    },
    _searchTitle: function(){
      if(this.$route.params.param1 == undefined){
        this.is_a_search =  false;
      }else{
        this.is_a_search = true;
        let param_1 = this.$route.params.param1;
        let param_2 = this.$route.params.param2;
        const dictSubSearch = {
          'last_week': "última semana",
          'last_month': "último mes",
          'last_three_months': "últimos 3 meses",
          'last_year': "último año",
          'Energy': "Energía",
          'Materials': "Materiales",
          'Utilities': "Utilidades",
          'Financials': "Finanzas",
          'Consumer Staples': "Productos de 1ª necesidad",
          'Real State': "Inmobiliario",
          'Comunication Services': "Comunicación",
          'Industrials': "Industrial",
          'Consumer Discretionary': "Consumo discrecional",
          'Health Care': "Salud",
          'Information Technology': "Tecnologías de la información",
        };
        const dictSearches = {
          "last-ipos": "Últimas IPOs",
          "best-bullish-values": "Valores alcistas " + dictSubSearch[param_2],
          "hot-values": "Más comentados " + dictSubSearch[param_2],
          "exchange": "Índice " + param_2,
          "sector":"Sector " + dictSubSearch[param_2],
          "platinum": "Valores Platino",
          "favorites": param_2,
          "twin-souls": "Almas gemelas",
          "ticker": "Ticker " + param_2,
          "waitings": "Valores en espera",
          "open-positions": "Posiciones abiertas",
          "all-values": "Valores en la cartera",
        };
        this.search_text = dictSearches[param_1];
      }
    },

    _setBrokerConnectionStatus: function(status) {
      this.$refs.stockchartMenuSecundario._setBrokerConnectionStatus(status);
    },

    _changePortfolioSelected: function(value){
      this.portfolio_id = value[0];
      this.setPortfolioIdSelected(this.portfolio_id);
      let success = (response) => {               
        this.getStocks();               
      };
      let url = '/api/v1/change-portfolio-invest-preferences/' + this.portfolio_id;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('223', 'Error cambiando las preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _nextTicker: function(){
      if(this._g_toggle_chart_stocks=='chart'){
        this.$refs.information._nextTicker();
      }else{
        this.$refs.stock_mobile._nextTicker();  
      }
    },

    _prevTicker: function(){
      if(this._g_toggle_chart_stocks=='chart'){
        this.$refs.information._prevTicker();
      }else{
        this.$refs.stock_mobile._previousTicker();  
      }
    },

    _changeChartList: function(){
      this.isChart = !this.isChart;
      if(this.isChart == true){
        this.$refs.topmenu._setMode("show-chart");
      }else{
        this.$refs.topmenu._setMode("drag-handle");
      }
    },
    
    _closeComponentInfoMobile:function () {
      this.$refs.stockchart_mobile_bottom._openInfo();
    },

    _closeComponentFavoritesMobile:function () {
      this.$refs.stockchart_mobile_bottom._openFavorites();
    },

    _closeComponentFundamentalMobile:function () {
      this.$refs.stockchart_mobile_bottom._openFundamental();
    },

    _closeComponentNotasMobile:function () {
      this.$refs.stockchart_mobile_bottom._openNotas();
    },

    _closeComponentDescriptionMobile:function () {
      this.$refs.stockchart_mobile_bottom._openDescription();
    },

    _closeComponentCommentsMobile:function () {
      this.$refs.stockchart_mobile_bottom._openComments();
    },

    _closeComponentErrorMobile:function () {
      this.$refs.stockchart_mobile_bottom._openError();
    },

    _closeComponentOrderMobile:function () {
      this.$refs.stockchart_mobile_bottom._openBroker();
    },

    _closeComponentSimulatorMobile:function () {
      this.$refs.stockchart_mobile_bottom._openSimulator();
    },

    _closeComponentDescriptionSearchMobile:function () {
      this.$refs.stockchart_mobile_bottom._openDescriptionSearch();
    },

    _getStreamingInfo : function(){
      let success = response =>{
        var aux = response.data;

        if(aux.status == "live"){
          this.is_streaming = true;
        }else{
          this.is_streaming = false;
        }

        this.streaming_date = aux.streaming_date;
        this.streaming_url = aux.streaming_url;
      }

      let url = "/api/v1/frontend/streaming/is_live"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('224', 'Error cargando emisión en directo.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    ///////////////////////////////////////////////////////////////////////////////////
    _getPortfolios: function(){
      let success = (response) => { 
        response.data.forEach(element => {
          this.user_portfolios.push({"name": element.idc, "value": element.name});
        });
      };

      let url = '/api/v1/portfolio/list';
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('225', 'Error recuperando la lista de carteras.');

      this._getAPICall(url, successHandler, failureHandler);

    },
    _defaultPortfolio: function(){
      let success = (response) => {               
        this.portfolio_selected.push({"name": response.data.idc, "value": response.data.name});
      };

      let url = '/api/v1/portfolio-invest-preferences';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('226', 'Error recuperando las preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _toastError(){
      let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
        window.location.replace("/app/store");
      }};
      this.$vueOnToast.pop(toast);
    },

    _refreshWorkspaceColors: function() {
      // Find all Stockchart and Stocks components in the component tree
      const refreshComponents = (component) => {
        // Check if this is a Stockchart component
        if (component.$options && component.$options.name === 'Stockchart') {
          // Try to find and call the Stocks component's _initWorkspaces
          component.$children.forEach(child => {
            if (child.$options && child.$options.name === 'Stocks') {
              if (typeof child._initWorkspaces === 'function') {
                child._initWorkspaces();
                return true;
              }
            }
          });
        }
        
        // Recursively check child components
        if (component.$children && component.$children.length) {
          for (const child of component.$children) {
            if (refreshComponents(child)) {
              return true;
            }
          }
        }
        
        return false;
      };
      
      // Start refreshing from this component
      refreshComponents(this);
    },
  },
  computed: {
    ...mapGetters(["is_tablet_device"]),
    ...mapGetters(["_g_IsUserLogged"]),
    ...mapGetters(["_g_option_mobile"]),
    ...mapGetters(["_g_chart_view"]),
    ...mapGetters(["_g_stocks_view"]),
    ...mapGetters(["_g_toggle_chart_stocks"]),
    ...mapGetters(["_g_open_info"]),
    ...mapGetters(["_g_open_fundamental"]),
    ...mapGetters(["_g_open_description"]),
    ...mapGetters(["_g_open_brain"]),
    ...mapGetters(["_g_open_simulator"]),
    ...mapGetters(["_g_open_order"]),
    ...mapGetters(["_g_open_notas"]),
    ...mapGetters(["_g_open_error"]),
    ...mapGetters(["_g_open_comments"]),
    ...mapGetters(["_g_open_filters"]),
    ...mapGetters(["_g_open_favorites"]),
    ...mapGetters(["_g_open_searches"]),
    ...mapGetters(['_g_ShowStockFinderFilter']),
    ...mapGetters(['_g_TickersFinder']),
    ...mapGetters(['_g_User']),
    ...mapGetters(['_g_UserFeatures']),
  },
}
</script>


<style lang="scss">
  @import "@/components/stockchart/styles/stockchart.scss";
  @import "@/styles/basics/globals.scss";
  
  html {
    overflow-y: hidden;
  }

  .stockchart-default{    
    z-index: 1;
    overflow: hidden;
  }

  .mobile-content {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  #sidebar-content {
    border-left: 1px solid grey;
    color: var(--color-text-secondary);
    background: #FFFFFF;
    margin: 0;
    padding: 0;
    height: 95%;
    position: fixed;
    width: 1px;
    top: 77px;
    right: 80px;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;

    .components {      
      > div {
        display:none;
        // height: calc(100vh); 
        }

      header {
        color: var(--color-typography-on-secondary);
        background: var(--color-primary-secondary);
        text-align: center;
        margin: 0;
        border-bottom: 1px solid white;
        margin-bottom: 20px;

        span.full-width {
          position: absolute;
          left: 10px;
          top: 20px;
          cursor: pointer;}

        h2 {
          margin: 4px 0px 4px 4px;}
      }
    }
  }

  .flex-container{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .searches-tittle{
    background-color: #000010;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }

  .close-searches{
    font-size: 16px;
    right: 30px;
    position: relative;
    margin-top: 10px;
    color: white;
    float: right;
  }

  .text-searches{
    margin-top: 10px !important;
    color: white;
  }

  .cartera-mobile{
    position: fixed;
    z-index: 4;
    background-color: transparent;
    top: 12px;
    right: 0;
    width: 40%;
    margin-right: 15px;
  }

  .iframe-container{
    height: 950px;
  }

  .pop-up-streaming{
    text-align:center;
    border: 1px solid black;
    width: 40%;
    height: 30%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
  }

  .flex-container.mobile-content{
    background-color: var(--custom-color-background-color-stockchart);
  }

  #sidebar-content {
    background-color: var(--color-surface);
  }
  .streaming-container{
    background-color: rgba(146, 146, 146, 0.15);;
    height: 100%;
    width: 100%;
  }

  .loading-hidden {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .loading-visible {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: var(--color-background, #fff);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
