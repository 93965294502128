<template>
  <!-- Course Promotion Banner -->
  <div class="top-promotion-banner">
    <div class="left-section">
      <h3>Curso online</h3>
      <p>Realiza el curso inicial de Eurekers gratuito durante las primeras 2 horas. <span class="highlight">Completa el curso y accede a todas las herramientas del graficador.</span></p>
    </div>
    <button @click="goToCourse" class="promotion-button">
      <span>VER CURSO</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TopBarAd',
  methods: {
    goToCourse() {
      // Direct to online.eurekers.com
      window.open('https://online.eurekers.com', '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.top-promotion-banner {
  height: 77px; // Exact height to match #nav-tools in Ai.vue
  background: linear-gradient(90deg, #001a36 0%, #002d5c 30%, #0056b3 100%); // Much darker start with 3-step gradient
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; // Prevent any content overflow
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  
  h3 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 4px 0;
    line-height: 1.2;
  }
  
  p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    margin: 0;
    line-height: 1.3;
    
    .highlight {
      color: #ffcb4c;
      font-weight: 500;
    }
  }
}

.promotion-button {
  background-color: white;
  color: #0056b3;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  height: 36px;
  min-width: 100px;
  cursor: pointer;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #f0f8ff;
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
  
  span {
    display: inline-block;
  }
}
</style>
